import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Helmet } from 'react-helmet'
import {Link} from 'gatsby'

import ContentThree from "../components/LivresBlancs/ContentThree"

import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const kebabCase = string => string
    .normalize("NFD").replace(/\p{Diacritic}/gu, "")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();





const LivreBlancFSC = ({ data }) => {
    
    return (
        <Layout>
            <Helmet>
                <title>Livre Blanc Remontées d'information</title>
                <meta name="description" content="..." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Livre Blanc Remontées d'information"
                homePageText="Accueil" 
                homePageUrl="/" 
                parentActive="Livres Blancs"
                parentPageUrl="/livres-blancs"
                activePageText="Livre Blanc Remontées d'information" 
            />
            <div className="blog-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <ContentThree />
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="widget-area">
                                <div className="widget widget_tracer_posts_thumb">
                                    <h3 className="widget-title">Nos derniers articles</h3>
                                    {data.lastThree.nodes.map(node => (
                                        <article className="item" key={node.id}>
                                            <Link to={"/"+node.frontmatter.slug} className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <GatsbyImage
                                                        image={getImage(node.frontmatter.hero_image)}
                                                        alt={node.frontmatter.hero_image_alt}
                                                        className='vignette-article'
                                                    />
                                                </span>

                                            </Link>
                                            <div className="info">
                                                <span>{node.frontmatter.date}</span>
                                                <h4 className="title usmall">
                                                    <Link to={"/"+node.frontmatter.slug}>
                                                        {node.frontmatter.title}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                    ))}
                                </div>
                                {/** Affichage des catégories */}
                                <div className="widget widget_categories">
                                    <h3 className="widget-title">Catégories</h3>
                                    <div className="tagcloud-custom">
                                        {data.cats.group.map(cat => (
                                            <Link to={"/cat/"+kebabCase(cat.category)} className={"default "+kebabCase(cat.category)} key={cat.category}>
                                                {cat.category} <span className="tag-link-count">{"("+cat.totalCount+")"}</span>
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="tagcloud-all center">
                                        <Link to="/blog">
                                        Tous les articles
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
}


export const query = graphql`
query {

    lastThree: allMdx (limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          id
          frontmatter {
            slug
            date(formatString: "MMM YYYY", locale: "fr")
            title
            hero_image {
                childImageSharp {
                  gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
        }
    }
    cats: allMdx{
        group(field: frontmatter___category) {
			category: fieldValue
            totalCount
        }
    }
}   
`




export default LivreBlancFSC;